<template>
  <v-container id="DeviceDetailView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">
              {{ patch_device ? patch_device.name : "Device Detail" }}
            </div>
            <v-breadcrumbs class="px-0 py-2" :items="breadcrumbs" divider="/" />
          </div>
          <v-btn
            small
            depressed
            disabled
            color="primary"
            @click.prevent="createNewDeviceBtnClicked"
          >
            Save & Sync
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <!-- Site Information -->
        <v-row>
          <v-col cols="12" class="pb-0">
            <span class="text-subtitle-1 font-weight-medium">
              Site Information</span
            >
          </v-col>
          <v-col cols="4">
            <v-text-field
              dense
              filled
              outlined
              disabled
              hide-details
              label="Site Name"
              placeholder="Site Name"
              :value="patch_device.site"
            />
          </v-col>
          <v-col cols="12" class="pb-0"> <v-divider></v-divider> </v-col>
          <v-col cols="12" class="pb-0">
            <span class="text-subtitle-1 font-weight-medium">
              Device Information</span
            >
          </v-col>
        </v-row>
        <!-- Site Information -->
        <CreateDeviceCell
          :edit_mode="true"
          v-bind="patch_device"
          @patch="updateDevice"
        />
      </v-col>
      <!-- <v-col cols="3">
        <span class="primary--text text-subtitle-2 font-weight-medium">
          Actions</span
        >
        <v-btn text small block color="grey darken-4" class="my-2">
          Disable Alarm
        </v-btn>
        <v-btn text small block color="grey darken-4" class="my-2">
          Disable Alert
        </v-btn>
        <v-btn text small block color="grey darken-4" class="my-2">
          Send Telemetry
        </v-btn>
        <v-btn text small block color="red" class="my-2">
          Delete This Device
        </v-btn>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import CreateDeviceCell from "@/components/cells/CreateDeviceCell";

export default {
  name: "DeviceDetailView",
  components: {
    CreateDeviceCell,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Devices",
          exact: true,
          disabled: false,
          to: { name: "device" },
        },
        {
          text: "Device Detail",
          exact: true,
          disabled: true,
          to: { name: "device-detail" },
        },
      ],
      device: {
        name: null,
        site: null,
      },
    };
  },
  computed: {
    ...mapState({
      patch_device: (state) => state.device.patch_device,
    }),
  },
  methods: {
    ...mapActions({
      requestGetDeviceDetailByID: "requestGetDeviceDetailByID",
    }),
    ...mapMutations({
      setPatchDeviceObject: "SET_PATCH_DEVICE_OBJECT",
      setPatchDeviceObjectField: "SET_PATCH_DEVICE_OBJECT_FIELD",
    }),
    updateDevice(params) {
      this.setPatchDeviceObjectField(params);
    },
  },
  async beforeMount() {
    // Call API : get current device detail by id
    const res_device = await this.requestGetDeviceDetailByID(
      this.$route.params.id
    );
    const brand_model = res_device.deviceModel.model.split("_");
    this.setPatchDeviceObject({
      site: res_device.siteId.siteName,
      type: res_device.deviceType,
      name: res_device.deviceName,
      brand: brand_model[0],
      model: brand_model[1],
      variables: res_device.deviceModel.variable,
      advance: res_device.deviceModel.advance,
    });

    // Assign breadcrumbs text
    this.breadcrumbs[1].text = this.patch_device.name;
  },
};
</script>
