<template>
  <v-row id="CreateDeviceCell">
    <v-col
      v-if="sequence"
      cols="12"
      class="d-flex justify-space-between align-center pb-0"
    >
      <span class="primary--text text-subtitle-2">
        Device #{{ sequence | zeroPadFormatter(3) }}</span
      >
      <div>
        <v-tooltip v-if="sequence !== 1" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              color="red"
              v-bind="attrs"
              v-on="on"
              @click="removeCreateDeviceObject(sequence)"
            >
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span> Remove This Device </span>
        </v-tooltip>
      </div>
    </v-col>
    <v-col cols="3">
      <v-select
        dense
        outlined
        :filled="edit_mode"
        :disabled="edit_mode"
        :clearable="!edit_mode"
        label="Device Type"
        item-value="value"
        :items="device_types"
        :value="type"
        @change="setDeviceType"
      >
        <template v-slot:item="{ item }">
          <div class="py-3">
            {{ item.text }}
            <span class="grey--text text--lighten-1">( {{ item.value }} )</span>
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col cols="3">
      <v-text-field
        dense
        outlined
        :filled="edit_mode"
        :disabled="edit_mode"
        :clearable="!edit_mode"
        label="Device Name"
        placeholder="Device Name"
        :value="name"
        @change="setDeviceName"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        dense
        outlined
        clearable
        label="Brand"
        :items="brands"
        :value="brand"
        @change="setDeviceBrand"
      >
        <template v-slot:item="{ item }">
          <div class="py-3">
            {{ item }}
          </div>
        </template>
        <template v-slot:no-data>
          <div class="px-4 py-2 text-center">
            <span class="text-subtitle-1">No data available</span> <br />
            <span class="grey--text text-caption"
              >( please select device type )</span
            >
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col cols="3">
      <v-select
        dense
        outlined
        clearable
        label="Model"
        item-text="model"
        item-value="model"
        :items="display_models"
        :value="model"
        @change="setDeviceModel"
      >
        <template v-slot:item="{ item }">
          <div class="py-3">
            {{ item.model }}
          </div>
        </template>
        <template v-slot:no-data>
          <div class="px-4 py-2 text-center">
            <span class="text-subtitle-1">No data available</span> <br />
            <span class="grey--text text-caption"
              >( please select device type )</span
            >
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col v-if="variables" cols="12" class="py-0">
      <span class="primary--text text-subtitle-2"> Variables</span>
    </v-col>
    <v-col cols="3" v-for="(value, key) in variables" :key="key">
      <v-text-field
        dense
        outlined
        clearable
        persistent-placeholder
        :label="key"
        :placeholder="variables[key]"
        v-model="variables[key]"
      />
    </v-col>
    <v-col v-if="advance" cols="12" class="py-0">
      <span class="primary--text text-subtitle-2"> Advance Variables</span>
    </v-col>
    <v-col cols="3" v-for="(value, key) in advance" :key="key">
      <v-text-field
        dense
        outlined
        clearable
        :label="key"
        :placeholder="key"
        v-model="advance[key]"
      />
    </v-col>
    <v-col cols="12" class="pb-0"> <v-divider></v-divider> </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "CreateDeviceCell",
  props: [
    "edit_mode",
    "sequence",
    "type",
    "name",
    "brand",
    "model",
    "variables",
    "advance",
  ],
  data() {
    return {
      device_name: null,
      selected_type: null,
      selected_brand: null,
      selected_model: null,
      brands: [],
      models: [],
      device_types: [
        // { text: "Battery", value: "BAT" },
        // { text: "Billing Meter", value: "BME" },
        // { text: "Grid Meter", value: "GME" },
        { text: "Inverter", value: "INV" },
        // { text: "Load Meter", value: "LME" },
        // { text: "Pressure", value: "PRE" },
        // { text: "Temperature", value: "RTD" },
        // { text: "Flow Meter", value: "FLM" },
        { text: "Sensor", value: "SEN" },
        { text: "Smart Logger", value: "SML" },
        { text: "Weather Station", value: "EMI" },
        // { text: "Lab Dummy", value: "LAB" },
        // { text: "Server", value: "SER" },
        // { text: "Machine", value: "MCH" },
        { text: "Meter", value: "MET" },
      ],
    };
  },
  computed: {
    display_models: function () {
      if (this.selected_brand && this.models.length > 0) {
        return this.models.filter((item) => item.brand === this.selected_brand);
      }
      return [];
    },
  },
  watch: {
    // whenever question changes, this function will run
    type(value) {
      if (value && this.edit_mode) {
        this.setDeviceType(this.type);
        this.setDeviceBrand(this.brand);
        this.setDeviceModel(this.model);
      }
    },
  },
  filters: {
    zeroPadFormatter: function (value, length) {
      return String(value).padStart(length, "0");
    },
  },
  methods: {
    ...mapActions({
      requestGetDeviceModelList: "requestGetDeviceModelList",
      requestGetDeviceModelVariableList: "requestGetDeviceModelVariableList",
    }),
    ...mapMutations({
      setCreateDeviceObject: "SET_CREATE_DEVICE_OBJECT",
      removeCreateDeviceObject: "REMOVE_CREATE_DEVICE_OBJECT",
    }),
    updateDeviceObjectField(sequence, field, value) {
      if (this.edit_mode) {
        this.$emit("patch", { field, value });
      } else {
        this.setCreateDeviceObject({
          sequence,
          field,
          value,
        });
      }
    },
    async requestDeviceBrandModelByType(value) {
      if (value) {
        const formatter_list = await this.requestGetDeviceModelList(value);
        this.brands = formatter_list.brands;
        this.models = formatter_list.models;
      }
    },
    async setDeviceType(value) {
      this.selected_type = value;
      this.updateDeviceObjectField(this.sequence, "type", value);
      await this.requestDeviceBrandModelByType(value);
    },
    setDeviceName(value) {
      this.device_name = value;
      this.updateDeviceObjectField(this.sequence, "name", value);
    },
    setDeviceBrand(value) {
      this.selected_brand = value;
      this.updateDeviceObjectField(this.sequence, "brand", value);
    },
    async setDeviceModel(value) {
      this.selected_model = value;
      this.updateDeviceObjectField(this.sequence, "model", value);

      if (value) {
        const params = {
          edit_mode: this.edit_mode,
          sequence: this.sequence,
          type: this.selected_type,
          brand_model: this.selected_brand + "_" + value,
        };
        await this.requestGetDeviceModelVariableList(params);
      }
    },
  },
};
</script>

<style></style>
