var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"id":"CreateDeviceCell"}},[(_vm.sequence)?_c('v-col',{staticClass:"d-flex justify-space-between align-center pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"primary--text text-subtitle-2"},[_vm._v(" Device #"+_vm._s(_vm._f("zeroPadFormatter")(_vm.sequence,3)))]),_c('div',[(_vm.sequence !== 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.removeCreateDeviceObject(_vm.sequence)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,false,648380548)},[_c('span',[_vm._v(" Remove This Device ")])]):_vm._e()],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"dense":"","outlined":"","filled":_vm.edit_mode,"disabled":_vm.edit_mode,"clearable":!_vm.edit_mode,"label":"Device Type","item-value":"value","items":_vm.device_types,"value":_vm.type},on:{"change":_vm.setDeviceType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3"},[_vm._v(" "+_vm._s(item.text)+" "),_c('span',{staticClass:"grey--text text--lighten-1"},[_vm._v("( "+_vm._s(item.value)+" )")])])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","filled":_vm.edit_mode,"disabled":_vm.edit_mode,"clearable":!_vm.edit_mode,"label":"Device Name","placeholder":"Device Name","value":_vm.name},on:{"change":_vm.setDeviceName}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"dense":"","outlined":"","clearable":"","label":"Brand","items":_vm.brands,"value":_vm.brand},on:{"change":_vm.setDeviceBrand},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3"},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2 text-center"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("No data available")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"grey--text text-caption"},[_vm._v("( please select device type )")])])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"dense":"","outlined":"","clearable":"","label":"Model","item-text":"model","item-value":"model","items":_vm.display_models,"value":_vm.model},on:{"change":_vm.setDeviceModel},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3"},[_vm._v(" "+_vm._s(item.model)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2 text-center"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("No data available")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"grey--text text-caption"},[_vm._v("( please select device type )")])])]},proxy:true}])})],1),(_vm.variables)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"primary--text text-subtitle-2"},[_vm._v(" Variables")])]):_vm._e(),_vm._l((_vm.variables),function(value,key){return _c('v-col',{key:key,attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","persistent-placeholder":"","label":key,"placeholder":_vm.variables[key]},model:{value:(_vm.variables[key]),callback:function ($$v) {_vm.$set(_vm.variables, key, $$v)},expression:"variables[key]"}})],1)}),(_vm.advance)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"primary--text text-subtitle-2"},[_vm._v(" Advance Variables")])]):_vm._e(),_vm._l((_vm.advance),function(value,key){return _c('v-col',{key:key,attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":key,"placeholder":key},model:{value:(_vm.advance[key]),callback:function ($$v) {_vm.$set(_vm.advance, key, $$v)},expression:"advance[key]"}})],1)}),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-divider')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }